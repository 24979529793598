html body {
    margin: 0;
    overflow-x: hidden;
    font-family: 'IBM Plex Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    font-family: 'IBM Plex Sans', 'Courier New', monospace, 'Droid Sans', 'Helvetica Neue', sans-serif;
    /* outline: 0.5px dashed red !important; */
}

