/* .header {
  display: flex;
  justify-content: center;
} */

.headerlink-title {
  color: #3F3F3F;
  text-decoration: none;
  /* padding: 0 5px; */
  font-weight : 700px;
}
